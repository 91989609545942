<template>
    <layout-main>
        <router-view/>
        <contact-dialog/>
    </layout-main>
</template>
<script>
import LayoutMain from "@/components/layout/LayoutMain";
import ContactDialog from "@/components/sections/ContactDialog";
export default {
    name: "APP",
    components: {
        LayoutMain,
        ContactDialog
    }
}
</script>
