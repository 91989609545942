<template>
    <v-container>
        <div
            v-for="(item, key) in text"
            :key="key"
            >
            <div
                v-if="item.type != 'list'"
                class="gray-text"
                :class="{
                    'text-title darken4 mt-12 mb-8': item.type == 'title',
                    'text-secondtitle darken4 mt-12 mb-6': item.type == 'group-title',
                    'text-h3 darken4 my-6': item.type == 'subtitle',
                    'text-body-2 mt-2': item.type == 'text'
                }"
                v-html="item.text"
            ></div>
            <div
                v-if="item.type == 'list'"
                class="gray-text text-body-2 my-2"
             >
                <ul>
                    <li v-for="(item, key) in item.items" :key="key" v-html="item"></li>
                </ul>
            </div>
        </div>
    </v-container>
</template>

<script>
export default {
    name: "DocsComponent",
    props: ['text']
}
</script>
<style>
.text-title{
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
}
.text-secondtitle{
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
}
</style>