<template>
    <div class="rounded-circle absolute" :style="'width:' + width +'px; height: ' + width + 'px;'"></div>
</template>

<script>
export default {
    name: "CircleElement",
    props: ['width']
}
</script>

<style>

</style>