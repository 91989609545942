<template>
    <div>
        <v-card class="cardti" :height="'324'" depressed>
            <v-card-text class="pa-6 pa-md-10 d-flex flex-column justify-space-between h-100 relative">
                <div style="z-index:10">
                    <div class="text-h3 mb-6 gray-text darken4 ls1px" v-html="item.title"></div>
                    <div class="text-body-2 gray-text mb-2 d-flex" v-for="(item, key) in item.items" :key="key">
                        <div><i class="fas fa-check mr-4"></i></div>
                        <div v-html="item"></div>
                    </div>
                </div>
                <div style="z-index:10">
                    <div class="text-body-2 gray-text" v-html="item.type"></div>
                    <div :class="{'text-h2': $vuetify.breakpoint.mdAndUp, 'text-h1': $vuetify.breakpoint.smAndDown}" class=" gray-text darken4 ls1px gilroy" v-html="item.value"></div>
                </div>
                <img class="absolute image my-6 mx-4 ma-md-10" :width="$vuetify.breakpoint.smAndDown ? 140 : 172" :src="item.image">
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "CardTI",
    props: ['item']
}
</script>

<style scoped>
.cardti{
    background: linear-gradient(129.86deg, #FFFFFF 0%, #FAFBFC 56.48%, #F5F7FA 108.99%) !important;
    box-shadow: 0px 1px 1px rgba(9, 13, 20, 0.06), -4px -4px 8px rgba(255, 255, 255, 0.4), 8px 8px 24px rgba(9, 13, 20, 0.06) !important;
}
.cardti .image.absolute{
    bottom: 0;
    right: 0;
}
</style>