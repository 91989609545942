module.exports = [
    {
        title: 'Incorporation Filing',
        type: 'Fixed',
        value: '$300',
        image: require('@/assets/images/services/circle.png'),
        items: [
            'State Organization or Incorporation Files',
            'Business License',
            'Federal EIN Number'

        ]
    },
    {
        title: 'Operating Authorities',
        type: 'Fixed',
        value: '$300',
        image: require('@/assets/images/services/plus.png'),
        items: [
            'DOT number',
            'MC number',
            'Brokerage Authority'
        ]
    },
    {
        title: 'Drug & Alcohol',
        type: 'Fixed',
        value: '$100',
        image: require('@/assets/images/services/star.png'),
        items: [
            "Drug and Alcohol <br/>Testing Program enrollment",
            'Clearinghouse compliance'
        ]
    },
    {
        title: 'Documents',
        type: 'Starting at',
        value: '$25',
        image: require('@/assets/images/services/triangle.png'),
        items: [
            'Company Files',
            'Driver Qualification Files',
            // 'Unit Files',
            'Ongoing Maintenance',
            // 'Form 2290',
            // 'Company Policies',
            'UCR Compliance'
        ]
    },
    {
        title: 'IFTA',
        type: 'Starting at',
        value: '$20',
        image: require('@/assets/images/services/plus.png'),
        items: [
            'Registration',
            'Calculations',
            'Filings',
            'IFTA License Update'
        ]
    },
    {
        title: 'Permits',
        type: 'Starting at',
        value: '$20',
        image: require('@/assets/images/services/circle.png'),
        items: [
            'Regular: NY, NM, OR, KY',
            'Annual',
            'Oversize/Overweight'
        ]
    },
    {
        title: 'HGRS ELD',
        type: 'Starting at',
        value: '$200',
        image: require('@/assets/images/services/oval.png'),
        items: [
            'ELD Hardware',
            'Cable',
            'ELD Support 24/7'
        ]
    },
    {
        title: 'Hours of Service Regulation',
        type: 'Starting at',
        value: '$150',
        image: require('@/assets/images/services/plus.png'),
        items: [
            "ELD/Exemption: 24/7 monitoring <br/>and support",
            'Driver HOS Training'
        ]
    },
    {
        title: 'DataQ',
        type: 'Starting at',
        value: '$50',
        image: require('@/assets/images/services/circle.png'),
        items: [
            'Violation Dispute',
            'Out of Service Violation'
        ]
    },
    {
        title: 'Bookkeeping',
        type: 'Fixed',
        value: '0.65%',
        image: require('@/assets/images/services/arrowup.png'),
        items: [
            'Accounting',
            'Billing for Factoring and Direct Billing'
        ]
    },
    {
        title: 'DOT',
        type: 'Starting at',
        value: '$99',
        image: require('@/assets/images/services/triangle.png'),
        items: [
            'New Entrant Safety Audits',
            'Focused Audits',
            'Corrective Action Plan'
        ]
    },
    {
        title: 'Consulting Services and Setup',
        type: 'Starting at',
        value: '$0',
        image: require('@/assets/images/services/arrowup.png'),
        items: [
            'Insurance',
            'Factoring Company',
            'Fuel Cards',
            'ELD'
        ]
    },
    {
        title: 'FMCSA',
        type: 'Fixed',
        value: '$50',
        image: require('@/assets/images/services/star.png'),
        items: [
            'Reapplication for a USDOT Number After Revocation',
            // 'Biennial Update',
            'Deactivation of USDOT Number',
            'Name/Address Change',
            // 'Reinstatement',
        ]
    },
];
