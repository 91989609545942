<template>
    <div class="relative">
        <circle-element class="blue lighten-4" width="336" :style="CircleOnedinamycStyle()"/>
        <circle-element class="blue lighten-4 d-none d-md-block" width="160" :style="CircleSeconddynamicStyle()"/>
        <div class=" py-2 py-md-10"></div>
        <v-container id="about">
            <div class="text-center">
                <div class="text-subtitle-1 primary--text my-6"><span>highest gears llc</span></div>
                <div class="text-h2 ls1px gray-text darken4"><shape-text imagestyle="margin-left:0px" imagenumber="2" textclass="px-2">About</shape-text>Us</div>
                <v-row class="mt-6">
                    <v-col cols="12" md="12" lg="10" offset-lg="1" xl="6" offset-xl="3" class="text-body-2 gray-text">
                        We founded Highest Gears LLC with a desire to ease the every-day management struggles of trucking companies and with an understanding that safety and health are of crucial importance for any work environment. We make sure that our clients stay focused on their destination, while it is our job to navigate through all the necessary procedures and documentation.
                    </v-col>
                </v-row>
                <v-row class="mb-12 mt-8 d-none d-md-flex text-center">
                    <v-col cols="12" md="6" lg="4">
                        <card-i-t-t title="Our Mission" text="By providing professional safety services, we make sure nothing prevents our clients from staying on track." :image="require('@/assets/images/svg/flag.svg')" :textclass="$vuetify.breakpoint.xl ? 'w-70 mx-auto' : 'mx-auto'"/>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <card-i-t-t title="Our Vision" text="We strongly believe that professionalism as well as full dedication yield the best results when it comes to the trucking industry that requires a high level of responsibility from everyone involved." :image="require('@/assets/images/svg/eye.svg')"/>
                    </v-col>
                    <v-col cols="12" md="12" lg="4">
                        <card-i-t-t title="Our Values" text="As a team, we’ve overcome difficulties together, and focus, commitment, honesty, and integrity have become the principles upon which we build honest and strong business relationships." :image="require('@/assets/images/svg/layers.svg')"/>
                    </v-col>
                </v-row>
            </div>
        </v-container>
        <v-slide-group
            v-model="aboutitem"
            class="pa-0 text-center d-md-none mt-6 mb-12"
            center-active
        >
            <v-slide-item class="ml-8 mr-2">
                <card-i-t-t style="width: 296px;" title="Our Mission" text="By providing professional safety services, we make sure nothing prevents our clients from staying on track." :image="require('@/assets/images/svg/flag.svg')" :textclass="$vuetify.breakpoint.xl ? 'w-70 mx-auto' : 'mx-auto'"/>
            </v-slide-item>
            <v-slide-item class="mx-2">
                <card-i-t-t style="width: 296px;" title="Our Vision" text="We strongly believe that professionalism as well as full dedication yield the best results when it comes to the trucking industry that requires a high level of responsibility from everyone involved." :image="require('@/assets/images/svg/eye.svg')"/>
            </v-slide-item>
            <v-slide-item class="ml-2 mr-8">
                <card-i-t-t style="width: 296px;" title="Our Values" text="As a team, we’ve overcome difficulties together, and focus, commitment, honesty, and integrity have become the principles upon which we build honest and strong business relationships." :image="require('@/assets/images/svg/layers.svg')"/>
            </v-slide-item>
        </v-slide-group>
    </div>
</template>

<script>
import ShapeText from "@/components/basic/ShapeText";
import CardITT from "@/components/basic/CardITT";
import CircleElement from "@/components/basic/CircleElement";
export default {
    name: "AboutSection",
    components: {
        ShapeText,
        CardITT,
        CircleElement
    },
    data: () => ({
        aboutitem: 1,
        showMore: false
    }),
    methods: {
        CircleOnedinamycStyle(){
            if(this.$vuetify.breakpoint.lgAndUp)
                return 'right:0;top:0; margin-right:-66px;margin-top: -104px';

            if(this.$vuetify.breakpoint.md)
                return 'right:0;top:0; margin-right:-100px;margin-top: -180px';

            return 'right:0;top:0; margin-right:-70px;margin-top: -130px; width:217px; height:217px;'
        },
        CircleSeconddynamicStyle(){
            if(this.$vuetify.breakpoint.md)
                return 'right:0;top:0; margin-right:-110px;margin-top: 282px';
            return 'right:0;top:0; margin-right:-80px;margin-top: 282px';
        }
    }
}
</script>

<style scoped>

</style>
