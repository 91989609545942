<template>
    <div>
        <v-dialog
            v-model="$store.state.dialog"
            max-width="872px"
            overlay-color="rgb(9, 13, 20)"
            overlay-opacity="0.48"
            content-class="box-shadow-none"
            :fullscreen="$vuetify.breakpoint.smAndDown"
        >
            <v-card flat :class="{'rounded-0': $vuetify.breakpoint.smAndDown}" style="background: linear-gradient(135deg, #FFFFFF 0%, #FAFBFC 51.82%, #F5F7FA 100%);">
                <v-card-text class="pa-10">
                    <div class="d-flex justify-space-between align-center">
                        <div class="text-h3 gray-text darken4 ls1px" style="font-weight: 700;">Request to Contact</div>
                        <div><img src="@/assets/images/svg/close1.svg" alt="close" class="pointer" @click="$store.state.dialog = false"></div>
                    </div>
                    <contact-component :compact="true"/>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="$store.state.confirm"
            max-width="425px"
            overlay-color="rgb(9, 13, 20)"
            overlay-opacity="0.48"
            content-class="box-shadow-none"
        >
            <v-card flat :class="{'rounded-0': $vuetify.breakpoint.smAndDown}" style="background: linear-gradient(135deg, #FFFFFF 0%, #FAFBFC 51.82%, #F5F7FA 100%);">
                <v-card-text class="pa-10 text-center">
                    <div class="mt-4 mb-10">
                        <img src="@/assets/images/svg/tg.svg" alt="svg"/>
                    </div>
                    <div class="text-h3 gray-text darken4 ls1px mb-3" style="font-weight: 700;">Thank you for your message</div>
                    <div class="text-body-2 gray-text mb-6">
                        We are happy you chose to contact us.<br/>
                        A member of our team will get back<br/>
                        to you with an answer shortly
                    </div>
                    <button-component color="primary" text="Continue" @click="$store.state.confirm = false"/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ContactComponent from "@/components/basic/ContactComponent";
import ButtonComponent from "@/components/basic/ButtonComponent";
export default {
    name: "ContactDialog",
    components: {
        ContactComponent,
        ButtonComponent
    }
}
</script>

<style scoped>

</style>