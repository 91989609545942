<template>
    <span v-bind="$attrs" class="relative">
        <img class="absolute w-100" :style="imgStyle()" :src="require('@/assets/images/svg/shape' + imagenumber + '.svg')" alt="">
        <span :class="textclass"><slot/></span>
    </span>
</template>

<script>
export default {
    name: "ShapeText",
    props: ['imagenumber', 'imagestyle', 'textclass', 'imgbottom'],
    methods:{
        imgStyle(){
            var style = 'top: 0;';
            if(this.imgbottom == true)
                style = 'bottom: 0;';
            if(this.imagestyle)
                style += this.imagestyle;
            console.log(style)
            return style;
        }
    }
}
</script>

<style scoped>
/*.shapetotext{*/
/*    background-image: url("@/assets/images/svg/shape1.svg");*/
/*    background-position: center;*/
/*    background-size: contain;*/
/*}*/
</style>