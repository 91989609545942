<template>
    <div>
        <v-container class="py-4 text-body-1 gray-text">
            <div class="d-flex pb-1 pb-md-4">
                <div class="d-block d-lg-none pr-6 pointer" @click="$router.push({name: 'privacy'})">Privacy Policy</div>
                <div class="d-block d-lg-none pointer" @click="$router.push({name: 'terms'})">Terms & Conditions</div>
            </div>
            <div class="d-flex justify-space-between flex-column flex-md-row">
                <div class="d-flex order-1 order-md-0">
                    <div class="d-flex">© {{ new Date().getFullYear() }} Highest Gears LLC <span class="d-none d-md-block">&nbsp;| All rights reserved</span></div>
                    <div class="d-none d-lg-block px-lg-8 px-xl-12 pointer" @click="$router.push({name: 'privacy'})">Privacy Policy</div>
                    <div class="d-none d-lg-block pointer" @click="$router.push({name: 'terms'})">Terms & Conditions</div>
                </div>
                <div class="order-0 order-md-1 pb-1 pb-md-0"><a href="https://gbmf.tech" target="_blank" class="text-decoration-none gray-text">created by <span class="font-weight-bold">GBMF</span></a></div>
            </div>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "FooterComponent"
}
</script>

<style scoped>

</style>