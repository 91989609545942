import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/view/Main.vue'
import Terms from "@/view/Terms";
import Privacy from "@/view/Privacy";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms
    },
    {
        name: 'privacy',
        path: '/privacy',
        component: Privacy
    }

    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
