<template>
    <div class="grey lighten-4 relative">
        <div class="py-5 py-md-14"></div>
        <v-container id="contact">
            <div>
                <div class="text-subtitle-1 primary--text ml-2 mb-4"><span>contact us</span></div>
                <div class="text-h2 gray-text darken4 ls1px"><shape-text imagenumber="5" textclass="px-1 mr-1">Contact</shape-text>Us</div>
            </div>
            <v-row class="mt-3 mt-md-6">
                <v-col cols="12" md="7" lg="7" xl="8">
                    <v-card flat class="contact-card">
                        <v-card-text class="pa-6 pa-md-10">
                            <div class="text-h3 gray-text darken4 ls1px" style="font-weight: 700;">Request to Contact</div>
                            <contact-component/>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="5" lg="5" xl="3" offset-xl="1">
                    <div class="text-h4 gray-text darken4 mt-6">
                        HIGHEST GEARS LLC<br/>
                        15866 Sweet Lemon Way<br/>
                        Winter Garden, FL 34787
                    </div>
                    <div class="mt-10">
                        <div class=""><a href="tel:(509) 590 1006" class="text-decoration-none gray-text darken4 gilroy">(509) 590 1006</a></div>
                        <div class="text-body-2 gray-text">Directory</div>
                    </div>
                    <div class="mt-6">
                        <div class=""><a href="tel:(509) 579 3104" class="text-decoration-none gray-text darken4 gilroy">(509) 579 3104</a></div>
                        <div class="text-body-2 gray-text">Safety support 24/7</div>
                    </div>
                    <div class="mt-6">
                        <div class=""><a href="tel:(509) 414 1916" class="text-decoration-none gray-text darken4 gilroy">(509) 414 1916</a></div>
                        <div class="text-body-2 gray-text">Oversize permits</div>
                    </div>
                    <div class="mt-6">
                        <div class=""><a href="tel:(509) 204 2571" class="text-decoration-none gray-text darken4 gilroy">(509) 204 2571</a></div>
                        <div class="text-body-2 gray-text">Accounting</div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div class="py-14"></div>
        <circle-element class="primary" width="152" style="right:0;bottom:0; margin-right:-70px;margin-bottom: 203px"/>
        <circle-element class="primary z1" width="304" style="right:0;bottom:0; margin-right:-40px;margin-bottom: -144px"/>
    </div>
</template>

<script>
import ShapeText from "@/components/basic/ShapeText";
import CircleElement from "@/components/basic/CircleElement";
import ContactComponent from "@/components/basic/ContactComponent";
export default {
    name: "ContactSection",
    components: {
        ShapeText,
        CircleElement,
        ContactComponent
    },
}
</script>

<style>
.contact-card{
    background: linear-gradient(135deg, #FFFFFF 0%, #FAFBFC 51.82%, #F5F7FA 100%);
    box-shadow: 0px 1px 1px rgba(9, 13, 20, 0.06), -4px -4px 8px rgba(255, 255, 255, 0.4), 8px 8px 24px rgba(9, 13, 20, 0.06)!important;
}
.theme--light.v-label{
    color: rgba(20, 16, 41, 0.4);
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
}
</style>