<template>
    <v-card flat color="grey lighten-3" class="h-100">
        <v-card-text class="pa-6 pa-md-10 pa-lg-6 pa-xl-10">
            <div class="icon rounded-circle primary white--text d-flex justify-center align-center mx-auto">
                <i v-if="icon" class="fa-xl far" :class="icon"></i>
                <img :src="image">
            </div>
            <div class="text-h3 gray-text darken4 my-6 ls1px" v-html="title"></div>
            <div class="text-body-2 gray-text" :class="textclass" v-html="text"></div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "CardITT",
    props: ['icon', 'image', 'title', 'text', 'textclass'],

}
</script>

<style scoped>
.icon{
    width: 96px;
    height: 96px;
}
.fa-xl{
    font-size: 28px!important;
}
</style>