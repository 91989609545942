module.exports = {
    titles: [
        {
            key: 'all',
            title: 'All Questions'
        },
        {
            key: 'eld',
            title: 'Electronic Logging Devices'
        },
        {
            key: 'rds',
            title: 'Records of Duty Status'
        },
        {
            key: 'sd',
            title: 'Supporting Documents'
        },
        {
            key: 'hs',
            title: 'Hours of Service'
        }
    ],
    qas: [
        {
            quest: 'Who must comply with the electronic logging device (ELD) rule?',
            answer: 'The ELD is mandatory for most motor carriers and drivers who are required to keep records of duty status (RODS) per Part 395, 49 CFR 395.8(a). This includes commercial buses as well as trucks, and drivers domiciled in Canada or Mexico. <br/>Last updated: March 15, 2017.',
            section: 'eld',
        },
        {
            quest: 'What are the exceptions to the ELD mandate?',
            answer: 'Only limited exceptions to the ELD mandate are allowed:' +
                '<ul>' +
                '<li>Drivers operating under the short-haul exceptions may still use timecards, without keeping RODS and without using ELDs</li>' +
                '<li>Drivers who use paper RODS for not more than 8 days out of every 30-day period</li>' +
                '<li>Drivers performing drive-away/tow-away operations, in which the driven vehicle is the one being delivered</li>' +
                '<li>Drivers of vehicles manufactured before 2000</li>' +
                '</ul><br/>' +
                'Last Updated: March 15, 2017.',
            section: 'eld',
        },
        {
            quest: 'What ELD user documentation must be onboard a driver\'s commercial motor vehicle (CMV)?',
            answer: 'As of December 18, 2017, the following paperwork is required from CMV drivers using an ELD:' +
                '<ul>' +
                '<li>A user\'s manual with instructions on how to operate the ELD</li>' +
                '<li>Detailed instructions describing the ELD data transfer mechanisms and how to produce and transfer a driver\'s hours-of-service records to an authorized safety official</li>' +
                '<li>An instruction sheet on reporting requirements and recordkeeping procedures in case of ELD malfunction</li>' +
                '<li>A supply of blank driver\'s RODS graph-grids sufficient to record the driver\'s duty status and other relevant information for no less than 8 days</li>' +
                '</ul>' +
                'Prior to December 18, 2017, FMCSA recommended that drivers have the user\'s manual, malfunction instruction sheet, and graph-grids.',
            section: 'eld',
        },
        {
            quest: 'What is the carrier\'s responsibility in ensuring that they are using a registered device?',
            answer: 'It is the motor carrier’s responsibility to check that their device is registered. They must check both the registration and revocation list periodically. The following is the link to the list of registered and revoked ELDs: <a href="https://eld.fmcsa.dot.gov/list" target="_blank">https://eld.fmcsa.dot.gov/list</a>.<br/>' +
                'If an ELD is taken off the registration list, FMCSA will strive to notify the public and affected users. It is advised that motor carriers and drivers sign up for ELD updates to receive notifications when an ELD has been placed on the revocation list.',
            section: 'eld',
        },
        {
            quest: 'Who can edit an electronic logging device record?',
            answer: 'Both the driver and authorized carrier staff can make limited edits to an ELD record to correct mistakes or add missing information. All edits must include a note that explains the reason for the edit. In addition, the driver must confirm that any carrier edit is accurate and resubmit the records. If the driver chooses not to recertify RODS, this is also reflected in the ELD record. The ELD must keep the original, unedited record, along with the edits.' +
                '<br/>Example: a carrier edits a record to switch a period of time from "off-duty" to "on-duty not driving", with a note that explains "Driver logged training time incorrectly as off-duty". The edit and note are sent to the driver for verification. The edit is not accepted until the driver confirms it and resubmits the RODS.',
            section: 'eld',
        },
        {
            quest: 'Can a driver be assigned driver records made under the Unidentified Driver profile and indicate a special driving category at the time the driver is assigned the driver records?',
            answer: 'Yes, a driver can be assigned unidentified driver records made under the Unidentified Driver profile and indicate a special driving category. However, an ELD must not allow automatically recorded driving time for a driver to be shortened or changed in any way.',
            section: 'eld',
        },
        {
            quest: 'What information is automatically recorded by an electronic logging device?',
            answer: 'An ELD automatically records the following data elements at certain intervals: date; time; location information; engine hours; vehicle miles; and identification information for the driver, authenticated user, vehicle, and motor carrier.',
            section: 'eld',
        },
        {
            quest: 'When is location data recorded by an electronic logging device?',
            answer: 'Location data must be recorded by an ELD at 60-minute intervals when the vehicle is in motion, and when the driver powers up and shuts down the engine, changes duty status, and indicates personal use or yard moves.',
            section: 'eld',
        },
        {
            quest: 'Does the vehicle location information identify street addresses?',
            answer: 'No. Vehicle location information is not sufficiently precise to identify street addresses. For each change in duty status, the ELD must convert automatically-captured vehicle position in latitude/longitude coordinates into geo-location information that indicates the approximate distance and direction to an identifiable location corresponding to the name of a nearby city, town, or village, with a State abbreviation.',
            section: 'eld',
        },
        {
            quest: 'What is the level of accuracy for commercial motor vehicle location information recorded by an electronic logging device?',
            answer: 'During on-duty driving periods, the location accuracy is approximately within a one-mile radius. When a driver operates a CMV for personal use, the position reporting accuracy would be roughly within a ten-mile radius.',
            section: 'eld',
        },
        {
            quest: 'How important is engine synchronization for the purposes of electronic logging device compliance?',
            answer: 'An ELD must be integrally synchronized with the engine of the commercial motor vehicle. Engine synchronization means monitoring engine operation to automatically capture the engine power status, vehicle motion status, miles driven, and engine hours.',
            section: 'eld',
        },
        {
            quest: 'When does an electronic logging device automatically start to record a driving mode or status?',
            answer: 'An ELD must automatically switch to driving mode once the commercial motor vehicle is moving up to a set speed threshold of five miles per hour. For this reason, the in-motion state must not be configured greater than five miles per hour. The vehicle will be considered stopped once its speed falls to zero miles per hour and stays at zero miles per hour for three consecutive seconds.',
            section: 'eld',
        },
        {
            quest: 'When does an ELD automatically change the duty status from “driving” to the default duty status of “on-duty not driving”?',
            answer: 'When the duty status is set to “driving”, and the commercial motor vehicle has not been in motion for five consecutive minutes, the ELD must prompt the driver to confirm a continued driving status or enter the actual duty status. If the driver does not respond to the ELD prompt within one minute, the ELD must automatically switch the duty status to “on-duty not driving”.',
            section: 'eld',
        },
        {
            quest: 'How can drivers present the records of duty status (RODS) for December 11 - December 17, 2017?',
            answer: 'Drivers do not have to present RODS on ELDs from December 11, 2017, to December 17, 2017. The previous 7 days of RODS can be presented through any of the following:' +
                '<ul>' +
                '<li>Paper records</li>' +
                '<li>A printout from an electronic logging device</li>' +
                '<li>A display from a device installed with logging software and electronic signature capabilities</li>' +
                '<li>Having the records available by entering them into an ELD</li>' +
                '<li>Continued use of a grandfathered automatic on-board recording device</li>' +
                '</ul>',
            section: 'rds',
        },
        {
            quest: 'How must a driver reflect their RODS for the previous 7 days during a roadside inspection, if they work for multiple motor carriers that are using ELDs?',
            answer: 'The driver has the following options:' +
                '<ul>' +
                '<li>Print out their hours-of-service from the other motor carrier</li>' +
                '<li>Transfer the ELD data from compatible devices between the motor carriers with the driver\'s approval</li>' +
                '<li>Manually add the hours of service while operating for that motor carrier into the current ELD using the editing and annotation functions of the ELD</li>' +
                '</ul>',
            section: 'rds',
        },
        {
            quest: 'Are drivers allowed to edit their RODS using the ELD back office support systems once they leave the commercial motor vehicle?',
            answer: 'Yes. Drivers may edit their RODS using ELD back office support systems. While these edits or corrections are allowed to ensure an accurate record of the driver\'s duty status, the electronic record must retain what was originally recorded as well as the date, time, and identity of the individual that made the edits.',
            section: 'rds',
        },
        {
            quest: 'What are the categories of supporting documents?',
            answer: 'Supporting documents required in the normal course of business are important to check a driver\'s RODS. They fall into the following categories described in 49 CFR 395.11(c):' +
                '<ul>' +
                '<li>Bills of lading, itineraries, schedules, or equivalent documents that indicate the origin and destination of each trip</li>' +
                '<li>Dispatch records, trip records, or equivalent documents</li>' +
                '<li>Expense receipts related to any on-duty not-driving time</li>' +
                '<li>Electronic mobile communication records, reflecting communications transmitted through a fleet management system</li>' +
                '<li>Payroll records, settlement sheets, or equivalent documents that indicate what and how a driver was paid</li>' +
                '</ul>' +
                'If a driver keeps paper RODS under 49 CFR 395.8(a)(1)(iii), the carrier must also retain toll receipts. If drivers use paper RODS, the eight-document rule is not applicable for toll receipts.',
            section: 'sd',
        },
        {
            quest: 'For how long must motor carriers retain RODS and supporting documents?',
            answer: 'Motor carriers are required to retain RODS and supporting documents for six months.',
            section: 'sd',
        },
        {
            quest: 'How many supporting documents must motor carriers retain, and when must drivers submit them to the motor carriers?',
            answer: 'For every 24-hour period that a driver is on duty, motor carriers must retain up to eight supporting documents. Drivers must submit their RODS and supporting documents to the motor carrier within 13 days after receiving them. If a motor carrier retains more than 8 supporting documents, the motor carrier must make sure to retain the first and last document issued during the regular course of business.',
            section: 'sd',
        },
        {
            quest: 'Can supporting documents be limited only to the ones acquired at the beginning and end of the workday?',
            answer: 'No. Documents acquired throughout the day are important in enforcing the 60/70-hour rule, which is crucial for ensuring hours of service compliance. Compliance with the 60/70-hour rule is based on the cumulative hours an individual works over a period of days. Supporting documents are critical to verify the proper duty statuses in assessing compliance with the 60/70 hour rules.',
            section: 'sd',
        },
        {
            quest: 'Can a user edit or change driving time that has been recorded by an electronic logging device to non-driving time?',
            answer: 'No. An ELD automatically records all of the time that a CMV is in motion as driving time and it cannot be edited or changed to non-driving time.<br/>' +
                'More information on hours of service regulations can be found here: <a href="https://www.fmcsa.dot.gov/regulations/hours-of-service" target="_blank">https://www.fmcsa.dot.gov/regulations/hours-of-service</a>',
            section: 'hs',
        },
        {
            quest: 'When a motor carrier discovers a driver in a team driving operation failed to log in and his or her activities were assigned to the co-driver, can the motor carrier reassign the generated data?',
            answer: 'For team drivers, the driver account associated with the driving time records may be edited and reassigned between the team drivers, if there was a mistake resulting in a mismatch between the actual driver and the driver recorded by the ELD, and if both team drivers were indicated in one another\'s records as a co-driver. In this case each co-driver must confirm the change for the corrective action to take effect.',
            section: 'hs',
        },
        {
            quest: 'What must a driver do with unassigned driving time when he or she logs into the ELD?',
            answer: 'A driver must review any unassigned driving time when he or she logs into the ELD. If the unassigned records do not belong to the driver, the driver must indicate that in the ELD record. If driving time logged under this unassigned account belongs to the driver, the driver must add that driving time to his or her own record.',
            section: 'hs',
        },
        {
            quest: 'May a user edit or correct driving time that has been wrongly recorded by an electronic logging device because the driver failed to change their duty status before powering off the CMV?',
            answer: 'Yes, but only if the driving time was recorded by the ELD while the vehicle was powered off and the vehicle was not in motion during the period that is being edited or corrected. \n' +
                'According to section 4.3.2.8.2(b) of the ELD rules, the editing of the automatically-recorded driving time is prohibited. The automatic recording of driving time ensures all movement of the CMV is captured.  A CMV cannot be driven while powered off. The driving time following the power off cycle of a CMV not in motion is not recorded to the specifications required by 4.3.1.2 and 4.4.1.1, so it may be edited to the correct duty status.',
            section: 'hs',
        }
    ]
}