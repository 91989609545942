<template>
    <v-btn
        v-bind="$attrs"
        v-on="$listeners"
        depressed
        :ripple="false"
        class="custom-box-shadow"
        :color="color"
    >
        {{text}}
        <i v-if="icon" :class="'ml-5 fas ' + icon + ' ' + clas"/>
        <img v-if="image" class="ml-4 mb-1" :src="image" style="height: 16px;"/>
    </v-btn>
</template>

<script>
export default {
    name: "ButtonComponent",
    props: ['color', 'text', 'icon', 'clas', 'image']
}
</script>

<style scoped>
.custom-box-shadow{
    box-shadow: 0px 0px 1px rgba(255, 171, 0, 0.24), 0px 2px 4px -1px rgba(10, 70, 82, 0.12), 0px 8px 8px -4px rgba(255, 171, 0, 0.12);
}
</style>