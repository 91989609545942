<template>
    <v-main class="overflow-hidden">
        <main-section/>
        <about-section/>
        <services-section/>
        <price-section/>
        <partners-section/>
        <f-a-q-section/>
        <contact-section/>
    </v-main>
</template>

<script>
var VueScrollTo = require('vue-scrollto');

import MainSection from "@/components/sections/MainSection";
import AboutSection from "@/components/sections/AboutSection";
import ServicesSection from "@/components/sections/ServicesSection";
import PriceSection from "@/components/sections/PriceSection";
import PartnersSection from "@/components/sections/PartnersSection";
import FAQSection from "@/components/sections/FAQSection";
import ContactSection from "@/components/sections/ContactSection";
export default {
    name: "MainPage",
    components: {
        MainSection,
        AboutSection,
        ServicesSection,
        PriceSection,
        PartnersSection,
        FAQSection,
        ContactSection
    },
    watch: {
        $route(to) {
            if(to.hash.length)
                this.goToHash(to.hash)
        }
    },
    mounted() {
        if(this.$route.hash.length)
            this.goToHash(this.$route.hash)

    },
    methods: {
        goToHash(hash){
            VueScrollTo.scrollTo(hash)
        }
    }
}
</script>

<style scoped>

</style>