<template>
    <div>
        <div class="py-16"></div>
        <docs-component :text="text"/>
    </div>
</template>

<script>
import text from '@/data/privacy.js';
import DocsComponent from "@/components/basic/DocsComponent";
export default {
    name: "PrivacyPage",
    components: {
        DocsComponent
    },
    data: () => ({
        text: text
    }),
    mounted(){
        window.scrollTo(0,0);
    }
}
</script>
