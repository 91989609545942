<template>
    <div class="relative" id="pricelist">
        <img class="pricelist-image-absolute pb-10 d-none d-lg-block" :style="pricelistImage()" src="@/assets/images/svg/pricelist.svg" alt="">
        <div class="py-2 py-md-10"></div>
        <v-container class="mb-1 relative">
            <circle-element class="primary d-none d-xl-block" width="234" style="right: 0; top: 0; margin-right: -78px; margin-top: -91px;"/>
            <v-card flat class="blue-gradient pricelistcard">
                <v-card-text class="pa-6 pa-md-16">
                    <div class="py-3 px-md-8">
                        <div class="text-center text-lg-left">
                            <div class="text-subtitle-1 primary--text ml-2 mb-4"><span>prices</span></div>
                            <div class="text-h2 gray-text darken4 ls1px"><shape-text imagenumber="3" imagestyle="margin-left:0px;" textclass="px-2 py-1 mr-1">Pricelist</shape-text></div>
                            <div class="mt-8 mb-6 text-body-1 gray-text darken1 o06">
                                You can download our full price list <br class="d-none d-md-block"/>to have this important information <br class="d-none d-md-block"/>at hand whenever you need it
                            </div>
                            <button-component color="primary" text="Download" :image="require('@/assets/images/svg/out.svg')" href="/files/Pricelist.pdf" target="_blank"/>
                        </div>
                        <div v-if="$vuetify.breakpoint.mdAndDown">
                            <div class="py-2 py-md-6"></div>
                            <div class="absolute text-center w-100" style="left: 0;right:0;">
                                <img class="mx-auto" :style="pricelistImage()" src="@/assets/images/svg/pricelist1.svg" alt="">
                            </div>
                            <div class="d-none d-md-block py-16"></div>
                            <div class="py-16 my-2"></div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>
        <div class="py-13"></div>
        <div class="py-16 d-none d-md-block d-lg-none"></div>
        <div class="py-8 d-none d-md-block d-lg-none"></div>

    </div>
</template>

<script>
import ShapeText from "@/components/basic/ShapeText";
import ButtonComponent from "@/components/basic/ButtonComponent";
import CircleElement from "@/components/basic/CircleElement";

export default {
    name: "PriceSection",
    components: {
        ShapeText,
        ButtonComponent,
        CircleElement
    },
    methods:{
        pricelistImage(){
            if(this.$vuetify.breakpoint.xl)
                return 'left: 400px;';
            if(this.$vuetify.breakpoint.lg)
                return 'left: 500px;';
            if(this.$vuetify.breakpoint.md)
                return 'left: 600px;';
            return 'width:320px;left: -12px;'
        }
    }
}
</script>

<style scoped>
.pricelist-image-absolute{
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    margin: auto;
}
@media all and (max-width: 400px){
    .pricelistcard{
        position: initial !important;
    }
}
</style>