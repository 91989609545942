<template>
    <div class="grey lighten-4 relative z10">
        <v-container :class="{'absolute absolute-y-center absolute-x-center': $vuetify.breakpoint.lgAndUp}" >
            <v-row class="h-100 align-center mt-16 pt-16 relative z50">
                <v-col cols="12" md="10" lg="8" xl="6" class="mt-md-6 mt-lg-10 d-none d-md-block">
                    <div class="text-h1 gray-text darken4 ls04em d-flex align-center">Shift Your <img class="ml-4" src="@/assets/images/png/gear.png" /></div>
                    <div class="text-h1 gray-text darken4 ls04em">Business to</div>
                    <div class="text-h1 gray-text darken4 ls04em">a Higher<shape-text imagenumber="1" textclass="pl-2 pr-3" :imgbottom="true">Gear</shape-text></div>
                    <div class="mt-5">
                        <button-component class="mt-15 ls04px" x-large color="primary" text="Get started" icon="fa-arrow-right" @click="$store.state.dialog = true"/>
                    </div>
                </v-col>
                <v-col cols="12" md="10" lg="8" xl="6" class="d-block d-md-none">
                    <div class="text-h1 gray-text darken4 ls04em">Shift Your Business</div>
                    <div class="text-h1 gray-text darken4 ls04em">to a Higher <shape-text imagenumber="1" :imgbottom="true" imagestyle="margin-bottom:2px;" class="pr-2">Gear</shape-text></div>
                    <div class="mt-5">
                        <button-component class="mt-md-15 ls04px" :x-large="$vuetify.breakpoint.mdAndUp" color="primary" text="Get started" icon="fa-arrow-right" @click="$store.state.dialog = true"/>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-row :class="{'relative': $vuetify.breakpoint.md}" :style="$vuetify.breakpoint.md ? 'margin-top: -180px' : ''">
            <v-col cols="12" md="12" lg="8" offset-lg="4" class="text-right">
                <img :src="getImage()" :height="getImageHeight()" style="mix-blend-mode: darken;" class="pb-xl-12 mb-md-0">
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ShapeText from "@/components/basic/ShapeText";
import ButtonComponent from "@/components/basic/ButtonComponent";
export default {
    name: "MainSection",
    components: {
        ShapeText,
        ButtonComponent
    },
    methods: {
        getImage(){
            if(this.$vuetify.breakpoint.xl)
                return require('@/assets/images/png/truck.png');
            if(this.$vuetify.breakpoint.lg)
                return require('@/assets/images/png/truck-lg.png');
            if(this.$vuetify.breakpoint.md)
                return require('@/assets/images/png/truck-md.png');

            return require('@/assets/images/png/truck-sm.png');
        },
        getImageHeight(){
            if(this.$vuetify.breakpoint.xl)
                return '972';
            if(this.$vuetify.breakpoint.lg)
                return '800';
            if(this.$vuetify.breakpoint.md)
                return '484';

            return '236';
        }
    }
}
</script>

<style scoped>

</style>