<template>
    <div class="grey lighten-4 relative overflow-hidden" id="partners">
        <circle-element class="blue lighten-4" width="170" style="left:0;bottom:0; margin-left:-85px;margin-bottom: 235px"/>
        <circle-element class="primary z1" width="335" style="left:0;bottom:0; margin-left:-48px;margin-bottom: -135px"/>
        <div class="py-2 py-md-11"></div>
        <v-container class="z10 relative">
            <div class="text-center mb-4 mb-md-8">
                <div class="text-subtitle-1 primary--text my-5"><span>partners</span></div>
                <div class="text-h2 gray-text darken4 ls1px">Our<shape-text imagenumber="4" imagestyle="margin-left:0px;" textclass="pt-1 pb-md-1 px-2">Partners</shape-text></div>
            </div>
            <v-row class="d-none d-md-flex">
                <v-col cols="12" md="6" lg="4" xl="3" :offset-lg="$vuetify.breakpoint.lg && key == 6 ? '2' : '0'" v-for="(item, key) in partners" :key="key">
                    <card-partner :item="item"/>
                </v-col>
            </v-row>
        </v-container>
        <v-slide-group
            class="pa-0 text-center d-md-none relative z10"
            center-active
        >
            <v-slide-item class="mx-2" v-for="(item, key) in partners" :key="key">
                <card-partner style="width: 296px;" :item="item"/>
            </v-slide-item>
        </v-slide-group>
        <div class="py-6 py-md-14"></div>
    </div>
</template>

<script>
import ShapeText from "@/components/basic/ShapeText";
import CardPartner from "@/components/basic/CardPartner";
import partners from '@/data/partners';
import CircleElement from "@/components/basic/CircleElement";

export default {
    name: "PartnersSection",
    components: {
        ShapeText,
        CardPartner,
        CircleElement
    },
    data: () => ({
        partners: partners
    }),
    methods: {

    }

}
</script>

<style scoped>

</style>