<template>
    <div class="mt-4">
        <v-container class="px-md-0 relative" id="services">
            <div class="">
                <div class="text-subtitle-1 primary--text mt-5 mb-2 my-md-5"><span>services</span></div>
                <div class="text-h2 ls1px gray-text darken4">Our<shape-text imagenumber="3" imagestyle="margin-left:0px;" textclass="px-2">Services</shape-text></div>
            </div>
            <circle-element class="blue lighten-4" :width="$vuetify.breakpoint.lg || $vuetify.breakpoint.md ? '209' : '160'" :style="circleOneStyle()"/>
            <v-row class="mt-4 mt-md-8 mb-5 mb-md-10 z10 relative">
                <v-col cols="12" lg="6" xl="4" class="changed-padding" v-for="(item, key) in getServices()" :key="key">
                    <card-t-i :item="item"/>
                </v-col>
            </v-row>
            <div class="text-center mb-4 z10 relative">
                <button-component id="servicebutton" color="primary" :text="!showAll ? 'Show more' : 'Show less'" :icon="!showAll ? 'fa-angle-down': 'fa-angle-up'" @click="changeButtonState()"/>
            </div>
            <circle-element class="blue lighten-4" width="268" style="left: 0; bottom: 0; margin-left: -112px;"/>
        </v-container>
    </div>
</template>

<script>
var VueScrollTo = require('vue-scrollto');

import ShapeText from "@/components/basic/ShapeText";
import CardTI from "@/components/basic/CardTI";
import services from "@/data/services";
import ButtonComponent from "@/components/basic/ButtonComponent";
import CircleElement from "@/components/basic/CircleElement";
export default {
    name: "ServicesSection",
    components: {
        ShapeText,
        CardTI,
        ButtonComponent,
        CircleElement
    },
    data: () =>({
        showAll: false
    }),
    methods: {
        getServices(){
            var limit;
            if(this.$vuetify.breakpoint.xl)
                limit = 9;
            if(this.$vuetify.breakpoint.lg)
                limit = 8;
            if(this.$vuetify.breakpoint.mdAndDown)
                limit = 4;

            var items = JSON.parse(JSON.stringify(services));
            if(this.showAll)
                return items;
            return items.splice(0, limit);
        },
        circleOneStyle(){
            if(this.$vuetify.breakpoint.xl)
                return 'right: 0; margin-right: 366px;';

            if(this.$vuetify.breakpoint.lg)
                return 'right: 0; margin-right: -100px;margin-top:-80px;';

            if(this.$vuetify.breakpoint.md)
                return 'right: 0; margin-right: -100px;margin-top:-80px;';

            if(this.$vuetify.breakpoint.sm)
                return 'right: 0; margin-right: -100px;margin-top:-60px;';
        },
        changeButtonState(){
            if(this.showAll){
                this.showAll = false;
                setTimeout(() => {
                    VueScrollTo.scrollTo('#servicebutton', 0, {offset: -500});
                }, 10)
            }else{
                this.showAll = true;
            }
        }
    }
}
</script>

<style scoped>
/*.changed-padding:nth-of-type(3n + 1) {*/
/*    padding-left: 0px;*/
/*    padding-right: 8px;*/
/*}*/
/*.changed-padding:nth-of-type(3n + 2) {*/
/*    padding-left: 8px;*/
/*    padding-right: 8px;*/
/*}*/
/*.changed-padding:nth-of-type(3n) {*/
/*    padding-left: 8px;*/
/*    padding-right: 0px;*/
/*}*/
</style>