module.exports = [
    {
        image: require('@/assets/images/logos/motive.svg'),
        name: 'Motive'
    },
    {
        image: require('@/assets/images/logos/mastereld.svg'),
        name: 'Master ELD'
    },
    {
        image: require('@/assets/images/logos/roadstar.svg'),
        name: 'Roadstar'
    },
    {
        image: require('@/assets/images/logos/gpstab.svg'),
        name: 'GPS Tab'
    },
    {
        image: require('@/assets/images/logos/trackensure.svg'),
        name: 'Trackensure'
    },
    {
        image: require('@/assets/images/logos/hos247.png'),
        name: 'HOS 247'
    },
    {
        image: require('@/assets/images/logos/reliableeld.svg'),
        name: 'Reliable ELD'
    },
    {
        image: require('@/assets/images/logos/ksk.png'),
        name: 'KSK',
        maxheight: 75
    }
]
