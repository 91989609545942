<template>
    <v-card flat class="partner-card">
        <v-card-text class="pa-6 text-center">
            <div class="partner-logo mx-auto rounded-circle mb-6 d-flex align-center justify-center">
                <img :src="item.image" style="mix-blend-mode: multiply; max-width: 96px;" :style="item.maxheight ? 'max-height:' +item.maxheight+ 'px': ''"/>
            </div>
            <div class="text-h4 gray-text darken1 o08 ls04px" v-html="item.name"></div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "CardPartner",
    props: ['item']
}
</script>

<style scoped>
.partner-card{
    background: linear-gradient(135deg, #FFFFFF 0%, #FAFBFC 51.82%, #F5F7FA 100%);
    box-shadow: 0px 1px 1px rgba(9, 13, 20, 0.06), -4px -4px 8px rgba(255, 255, 255, 0.4), 8px 8px 24px rgba(9, 13, 20, 0.06) !important;
}
.partner-logo{
    width: 120px;
    height: 120px;
    background: linear-gradient(135deg, #EFF6FF 0%, #ECF4FF 51.82%, #D8E6FC 100%);
}
</style>
