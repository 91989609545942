<template>
    <div class="relative z10">
        <div class="py-4 py-md-14"></div>
        <v-container id="faq">
            <div class="text-subtitle-1 primary--text ml-2 mb-5"><span>FAQ</span></div>
            <div class="text-h2 gray-text darken4 ls1px">Frequently<br/>Asked Questions</div>
            <v-row class="mt-md-0 mt-lg-0 mt-xl-5">
                <v-col cols="0" xl="3">
                    <v-list class="grey lighten-3 d-none d-xl-block">
                        <v-list-item class="faq-menu-section-item relative" v-for="(item, key) in titles" :key="key" :class="{'active': section == item.key}" @click="section = item.key; panel = false0; getQueries()" :ripple="false">
                            <v-list-item-title class="gray-text darken4" :class="{'text-subtitle-2': section == item.key, 'text-body-1': section != item.key}" v-html="item.title"></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" xl="9">
                    <div>
                        <v-select
                            v-model="section"
                            :items="titles"
                            item-text="title"
                            item-value="key"
                            background-color="#EFF6FF"
                            solo
                            flat
                            class="selectfaqtitle pb-8 d-xl-none"
                            :ripple="false"
                            hide-details
                            hide-selected
                            append-icon="fas fa-angle-down"
                            @change="panel = false"
                        ></v-select>
                    </div>
                    <v-expansion-panels flat v-model="panel">
                        <v-expansion-panel class="faq-extension-panel mb-4 px-1 py-2" v-for="(item, key) in getQueries()" :key="key">
                            <v-expansion-panel-header class="text-h5 gray-text darken4 ls04px" disable-icon-rotate>
                                <span class="pr-3">
                                    {{item.quest}}
                                </span>
                                <template v-slot:actions>
                                    <i class="fas fa-sm" :class="{'fa-plus': panel !== key, 'fa-minus': panel === key}"></i>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="text-body-2 gray-text mr-md-16">
                                <span v-html="item.answer"></span>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <div class="text-center mt-9">
                        <button-component id="faqbutton" v-if="queriesCount > 7" color="primary" :text="!showAll ? 'Show more' : 'Show less'" :icon="!showAll ? 'fa-angle-down' : 'fa-angle-up'" @click="changeButtonState()"/>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div class="py-6 pa-md-14"></div>
    </div>
</template>

<script>
var VueScrollTo = require('vue-scrollto');

import FaqData from '@/data/faq';
import ButtonComponent from "@/components/basic/ButtonComponent";

export default {
    name: "FAQSection",
    components: {
        ButtonComponent
    },
    data: () => ({
        panel: false,
        section: 'all',
        showAll: false,
        titles: FaqData.titles,
        queriesCount: 0
    }),
    methods: {
        getQueries(){
            var items = JSON.parse(JSON.stringify(FaqData.qas));

            // this.panel = 0;

            if(this.section != 'all')
                items = items.filter(item => {
                    return item.section == this.section;
                });

            this.queriesCount = items.length;

            if(this.showAll)
                return items;

            return items.splice(0, 7);
        },
        changeButtonState(){
            if(this.showAll){
                this.showAll = false;
                setTimeout(() => {
                    VueScrollTo.scrollTo('#faqbutton', 1000, {offset: -500});
                }, 10)
            }else{
                this.showAll = true;
            }
        }
    }
}
</script>

<style>
.faq-extension-panel{
    background: linear-gradient(135deg, #FFFFFF 0%, #FAFBFC 51.82%, #F5F7FA 100%);
    box-shadow: 0px 1px 1px rgba(9, 13, 20, 0.06), -4px -4px 8px rgba(255, 255, 255, 0.4), 8px 8px 24px rgba(9, 13, 20, 0.06)!important;
}
.faq-menu-section-item.v-list-item--link::before{
    content: none;
}
.faq-menu-section-item.active::before{
    content: " ";
    width: 2px;
    height: 100%;
    background: #FFAB00;
    position: absolute;
    left: 0;
    opacity: 1 !important;
}
.selectfaqtitle .v-input__slot{
    padding-left: 24px!important;
    padding-right: 24px!important;
}
</style>