<template>
    <v-app>
        <header-component/>
        <slot/>
        <footer-component/>
    </v-app>
</template>

<script>
import HeaderComponent from './Header';
import FooterComponent from "@/components/layout/Footer";

export default {
    name: "LayoutMain",
    components: {
        HeaderComponent,
        FooterComponent,
    }
}
</script>

<style scoped>

</style>