<template>
    <div class="z50">
        <v-app-bar
            flat
            absolute
            :color="$route.name === 'home' ? 'grey lighten-4' : 'white'"
            height="96"
            class="px-0 z100"
        >
            <v-overlay :value="overlay" z-index="51"></v-overlay>
            <v-container class="relative z100" :class="!menuState ? 'whitespace' : 'white'">
                <div class="d-flex justify-space-between relative z10">
                    <div class="d-flex align-center">
                        <img src="@/assets/logo.svg" :width="$vuetify.breakpoint.smAndDown ? '172' : ' '" class="mr-sm-6 pointer" @click="$router.push('/')" alt=""/>
                        <div
                            class="mx-6 gray-text darken4 d-none d-xl-block pointer"
                            v-for="(item, key) in menu"
                            :key="key"
                            v-html="item.name"
                            @click="$router.push({name: 'home', hash: '#' + item.key})"
                        ></div>
                    </div>
                    <div class="d-flex">
                        <button-component class="d-none d-md-block" color="primary" text="Get Started" @click="$store.state.dialog = true"/>
                        <v-btn class="d-xl-none burger-button px-0 ml-6" style="width: 48px; min-width: 48px;" :ripple="false" depressed @click="changeMenuState()">
                            <img v-if="!menuState" src="@/assets/images/svg/menu.svg" alt="">
                            <img v-if="menuState" src="@/assets/images/svg/close.svg" alt="">
                        </v-btn>
                    </div>
                </div>
                <v-card v-if="menuState" depressed class="absolute rounded-0 headermenupopupboxshadow" style="left: 0; right: 0; margin-top: 70px;top: 0">
                    <v-list>
                        <v-list-item v-for="(item, key) in menu" :key="key" class="pointer" @click="mobileMenuClick('#' + item.key)">
                            <v-list-item-title class="d-flex align-center">
                                <img :src="require('@/assets/images/svg/' + item.icon + '.svg')" class="mr-5">
                                <span class="text-body-1" v-html="item.name"></span>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="d-md-none">
                            <v-list-item-title class="text-center mt-5">
                                <button-component class="d-block d-md-none w-100" color="primary" text="Get Started" @click="$store.state.dialog = true"/>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-container>
        </v-app-bar>
        <v-overlay :value="overlay" z-index="51"></v-overlay>
    </div>
</template>

<script>
var VueScrollTo = require('vue-scrollto');

import ButtonComponent from "@/components/basic/ButtonComponent";
export default {
    name: "HeaderComponent",
    components: {
        ButtonComponent
    },
    data: () => ({
        menu: [
            {name: "About Us", key: "about", icon: 'globe'},
            {name: "Our Services", key: "services", icon: 'star'},
            {name: "Pricelist", key: "pricelist", icon: 'bond'},
            {name: "Our Partners", key: "partners", icon: 'people'},
            {name: "FAQ", key: "faq", icon: 'file'}
        ],
        menuState: false,
        overlay: false,
        dialog: true
    }),
    methods: {
        mobileMenuClick(element){
            VueScrollTo.scrollTo(element)
            this.menuState = !this.menuState
            this.overlay = false
        },
        changeMenuState(){
            this.overlay = this.menuState = !this.menuState
        }
    }
}
</script>

<style>
.burger-button{
    background: linear-gradient(135deg, #FFFFFF 0%, #FAFBFC 51.82%, #F5F7FA 100%);
    box-shadow: 0px 1px 1px rgba(9, 13, 20, 0.06), -4px -4px 8px rgba(255, 255, 255, 0.4), 8px 8px 24px rgba(9, 13, 20, 0.06);
    border-radius: 12px;
}
.headermenupopupboxshadow{
    box-shadow: 0px 0px 1px rgba(255, 171, 0, 0.24), 0px 2px 4px -1px rgba(10, 70, 82, 0.12), 0px 8px 8px -4px rgba(255, 171, 0, 0.12)!important;
}
</style>